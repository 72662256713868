import * as React from 'react';
import {useField} from 'formik';

import {Input, InputProps} from './Input';

export const InputFormik = ({name, ...props}: InputProps) => {
    const [field, meta, helpers] = useField(name);

    return (
        <Input
            {...props}
            {...field}
            error={meta.touched && meta.error}
            onBlur={() => {
                helpers.setTouched(true);
            }}
            onChangeString={(val) => {
                if (props.type === `number`) {
                    helpers.setValue(parseInt(val));
                } else {
                    helpers.setValue(val);
                }
            }}
        />
    );
};
