/**
 * Replaces quotation marks with &quot, making the string
 * suitable for HTML attributes
 * @param quotedHTML - The string containing quotation marks
 * @returns {string} - String without quotation marks
 */
export function replaceHTMLQuotes(quotedHTML?: string): string {
    if (!quotedHTML) {
        return ``;
    }
    return quotedHTML.replace(/"/, `&quot;`);
}
